import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "title": "Edit Target", "after-close": _vm.afterModalClose }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": function($event) {
        _vm.visible = false;
      } } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary", "loading": _vm.isLoading }, on: { "click": function($event) {
        return handleSubmit(_vm.onSubmit);
      } } }, [_vm._v(" Save ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 16 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Facility", "value": _vm.entity.facilityName, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Department", "value": _vm.entity.areaName, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Line", "value": _vm.entity.productionLineCode, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "OEE Target", "rules": "required", "value": _vm.entity.oeeTarget, "suffix": "%", "type": "decimal", "min": 0, "max": 100 }, on: { "change": function($event) {
      return _vm.storeValue("oeeTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Availability Target", "rules": "required", "value": _vm.entity.availabilityTarget, "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("availabilityTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Performance Target", "rules": "required", "value": _vm.entity.performanceTarget, "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("performanceTarget", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("number-input", { attrs: { "form-item": "", "label": "Quality Target", "rules": "required", "value": _vm.entity.qualityTarget, "suffix": "%", "type": "decimal", "min": 0, "max": 100, "span": 12 }, on: { "change": function($event) {
      return _vm.storeValue("qualityTarget", $event);
    } } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditTargets",
  inject: ["crud", "apiUrl", "redirectRoute"],
  data() {
    return {
      visible: false,
      isLoading: false,
      editForm: {}
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || {};
    }
  },
  mounted() {
    this.visible = true;
    this.crud.fetchEntity();
    this.storeValues(this.entity);
  },
  methods: {
    afterModalClose() {
      this.crud.clearEntity("editForm");
      this.$router.push(this.redirectRoute);
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      Object.entries(entity).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    async onSubmit() {
      await this.crud.submitEntity("replace");
      await this.crud.fetchList();
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditTargets = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-targets" }, [_c("resource", { attrs: { "name": "line-performance.common.month-target", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.targets", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/targets" } }, [_c("edit-targets")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditTargets
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
